import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Login from './Page/Login'
import LoginUser from './Page/LoginUser'
// import RememberPassword from './Page/RememberPassword'
// import Main from './Page/Main'
// import About from './Page/About'
import PaymentInfo from './Page/PaymentInfo'
import PaymentDo from './Page/PaymentDo'

import LoginAdmin from './Page/Admin/Login'
import PaymentList from './Page/Admin/PaymentList'
import Instalment from './Page/Admin/Instalment'
import Params from './Page/Admin/Params'

// import AkbankPay from './Page/Test/AkbankPay';

class App extends Component {
  render() {
    return (
      <div className="App container">
        <Router>
          <Switch>
            <Route exact path='/' component={PaymentInfo} />
            {/* <Route exact path='/' component={LoginUser} /> */}

            <Route path='/loginuser' component={LoginUser} />
            {/* <Route path='/remember-password' component={RememberPassword} /> */}
            {/* <Route path='/main' component={Main} /> */}
            {/* <Route path='/about' component={About} /> */}
            <Route path='/paymentinfo' component={PaymentInfo} />
            <Route path='/paymentdo' component={PaymentDo} />

            <Route path='/loginadmin' component={LoginAdmin} />
            <Route path='/paymentlist' component={PaymentList} />
            <Route path='/instalment' component={Instalment} />
            <Route path='/params' component={Params} />

            {/* <Route path='/TestAk' component={AkbankPay} /> */}
          </Switch>
        </Router>
      </div>
    )
  }
}
export default App;
